import { Button, Flex, Input, Layout, Space, Switch, Table } from "antd";
import Header from "../../components/layout/Header";
import { Icon } from "../../components/ui/typo/Icon";
import { Text } from "../../components/ui/typo/Text";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../models/utils/data-paginate";
import userService from "../../services/user.service";
import User, { ERole } from "../../models/user";
import { columns } from "../../configs/UserTableColumns";
import PanelFormUser from "../../components/core/users/panel-form-user/panel-form-user";
import ButtonColumn from "../../components/ui/buttons/button-column";
import useDebounce from "../../utils/hooks/useDebounce";
import useTable from "../../utils/hooks/useTable";
import DeletionModal from "../../components/ui/modal/deletion-modal";
import { useAppDispatch } from "../../store/hooks";
import { addToast } from "../../store/toasts/reducer";
import Toast from "../../models/utils/toast";
import HasPermission from "../../components/core/commons/has-permission/has-permission";
import Avatar from "../../components/ui/avatar/avatar";


export default function ListUsersPage() {
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [showModal, setShowModal] = useState(false);
    const [editingUser, setEditingUser] = useState<User>();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState(false);
    const { selectedRowKeys, rowSelection, visibleColumns, setVisibleColumns, renderColumnsAndSelection, showModalDeletion, setShowModalDeletion, resetRowSelection } = useTable(columns, isDisabled)
    const [showModalUnarchive, setShowModalUnarchive] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                resetRowSelection();
                setLoading(true);
                const res = await userService.list(page, search, isDisabled);
                setDatapaginate(res);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [page, search, isDisabled])

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: User) => {
            return {
                id: item.id,
                key: item.id,
                full_name: <Flex align="center" gap={10}> <Avatar placeholder={item.getLettersName()} /> { item.getFullName()} </Flex>,
                email: item.email || '-',
                role: item.getRolesName() || '-',
                agency_name: item.agencies.length ? item.agencies.map(agencie => agencie.name).join(', ') : '-',
                code: item.code || '-',
            }
        });
    }, [dataPaginate]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);

    async function onClose(refresh?: boolean) {
        setShowModal(false)
        setEditingUser(undefined);
        if (refresh) {
            try {
                setLoading(true);
                const res = await userService.list();
                setDatapaginate(res);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                resetRowSelection();
            }
        }
    }

    function onEditUser() {
        const user = dataPaginate?.data?.find(item => item.id === selectedRowKeys[0]);
        if (user) {
            setEditingUser(user);
            setShowModal(true);
        }
    }

    async function onDelete() {
        try {
            setLoading(true);
            const listToDelete = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToDelete) {
                for (const item of listToDelete) {
                    await userService.destroy(item.id);

                }
                dispatch(addToast(new Toast('Les utilisateurs ont étaient supprimés avec succès', 'success')))
                if(dataPaginate && dataPaginate.data.length === selectedRowKeys.length){
                    setPage(page - 1)
                } else {
                    const res = await userService.list(page, search);
                    setDatapaginate(res);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalDeletion(false);
            resetRowSelection();
        }
    }

    function onUnarchiveUser() {
        setShowModalUnarchive(true);
    }

    async function onUnarchive() {
        try {
            setLoading(true);
            const listToUnarchive = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToUnarchive) {
                for (const item of listToUnarchive) {
                    await userService.unarchive(item.id);

                }
                dispatch(addToast(new Toast('Les utilisateurs ont étaient réactivés avec succès', 'success')))
                if(dataPaginate && dataPaginate.data.length === selectedRowKeys.length){
                    setPage(page - 1)
                } else {
                    const res = await userService.list(page, search, isDisabled);
                    setDatapaginate(res);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalUnarchive(false);
            resetRowSelection();
        }
    }

    return (
        <HasPermission permission={ERole.ROLE_MANAGE_USERS}>
            <Header title="Gestion des utilisateurs">
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <Space size={6}>
                        <Icon path="/icons/plus.svg" size={20} />
                        <Text target="Bold">Ajouter un utilisateur</Text>
                    </Space>
                </Button>
            </Header>
            <Layout className="container">

                <Flex vertical gap={16}>
                    <Flex justify="space-between">
                        <Flex gap={12}>
                            <Input
                                size="large"
                                placeholder="Rechercher..."
                                prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                                style={{ width: 320 }}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <Space size={8}>
                                <Switch checked={isDisabled} onChange={() => setIsDisabled(!isDisabled)} />
                                <Text target="Bold">Utilisateurs archivés</Text>
                            </Space>
                        </Flex>
                        

                        <Space size={8}>
                            <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} keyCache="users" />
                        </Space>
                    </Flex>

                    <Table
                        rowKey={"id"}
                        loading={loading}
                        columns={renderColumnsAndSelection(visibleColumns, selectedRowKeys, onEditUser, onUnarchiveUser)}
                        dataSource={dataTable}
                        scroll={{ x: 1327, y: 1080 }}
                        showSorterTooltip={false}
                        onChange={(pagination, filters, sorter, extra) => {
                            console.log(pagination, filters, sorter, extra);
                        }}
                       //onRow={(record) => { return { onClick: () => onEditUser(record) } }}
                        pagination={{
                            current: page,
                            pageSize: dataPaginate?.per_page || 10,
                            total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page)
                            },
                        }}
                        rowSelection={rowSelection}
                    />
                </Flex>
            </Layout>
                        
            {showModal && <PanelFormUser user={editingUser} showModal={showModal} onClose={(refresh) => onClose(refresh)} />}
            {showModalDeletion && <DeletionModal
                modalOpen={showModalDeletion}
                title="Suppression d'utilisateur"
                description="Voulez-vous vraiment supprimer ces utilisateurs ?"
                onCancel={() => setShowModalDeletion(false)}
                onConfirm={() => onDelete()}
            />}

            {showModalUnarchive && <DeletionModal
                modalOpen={showModalUnarchive}
                title="Réactiver le(s) utilisateur(s) ?"
                description="Voulez-vous vraiment les réactiver ?"
                onCancel={() => setShowModalUnarchive(false)}
                onConfirm={() => onUnarchive()}
                icon='/icons/archive-expand.svg'
            />}
        </HasPermission>
    )
}