import { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Layout,
  Row,
  Col,
  Card,
  Space,
  Tag,
  Table,
  Empty,
} from "antd";
import { Text } from "../../components/ui/typo/Text";
import { Icon } from "../../components/ui/typo/Icon";
import Header from "../../components/layout/Header";
import DataPaginate from "../../models/utils/data-paginate";
import useTable from "../../utils/hooks/useTable";
import Task from "../../models/task";
import { columns } from "../../configs/TaskTableColumns";
import taskService from "../../services/task.service";
import LabelTaskName from "../../components/core/notes/label-task-name";
import Avatar from "../../components/ui/avatar/avatar";
import dateHelper from "../../utils/date.helper";
import StatusTask from "../../components/core/tasks/status-task";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import PanelFormTask from "../../components/core/tasks/panel-form-task";
import DeletionModal from "../../components/ui/modal/deletion-modal";
import { addToast } from "../../store/toasts/reducer";
import Toast from "../../models/utils/toast";
import dashboardService, { DashboardData } from "../../services/dashboard.service";
import { set } from "react-hook-form";

export default function HomePage() {
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState(1);
    const { selectedRowKeys, rowSelection, visibleColumns, renderColumnsAndSelection, showModalDeletion, setShowModalDeletion, resetRowSelection } = useTable(columns)
    const {value : user} = useAppSelector(state => state.user);
    const [editingTask, setEditingTask] = useState<Task>();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useAppDispatch();
    const [dashboardData, setDashboardData] = useState<DashboardData>();



    useEffect(() => {
        async function fetchData() {
            if (user) {
                try {
                    setLoading(true);
                    const res = await taskService.list(page, '', user.id);
                    setDatapaginate(res);
                    setDashboardData( await dashboardService.get());
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoading(false);
                }
            }
        }
        fetchData();
    }, [page, user])

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Task) => {
            return {
                id: item.id,
                key: item.id,
                title: <LabelTaskName task={item} /> || '-',
                user: item.user ? <Avatar placeholder={item.user.getLettersName()} /> : '-',
                due_date: item.due_date ? dateHelper.dateToDDMMYYYY(item.due_date) : '-',
                client_name: item.client?.name || '-',
                status: <StatusTask task={item} /> || '-',
                reference: item.reference || '-',
            }
        });
    }, [dataPaginate]);

    async function onEditTask() {
        const selectedTask = dataPaginate?.data?.find(item => item.id === selectedRowKeys[0]);

        if (selectedTask) {
            const task = await taskService.find(selectedTask.id);
            setEditingTask(task);
            setShowModal(true);
        }
    }

    async function onClose(refresh?: boolean) {
        setShowModal(false)
        setEditingTask(undefined);
        if (refresh && user) {
            try {
                setLoading(true);
                const res = await taskService.list(page, '', user.id);
                res.setData(res.data.map(item => new Task(item)));
                setDatapaginate(res);
                
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                resetRowSelection();
            }
        }
    }

    async function onDelete() {
        try {
            setLoading(true);
            const listToDelete = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToDelete && user) {
                for (const item of listToDelete) {
                    await taskService.destroy(item.id);

                }
                dispatch(addToast(new Toast('Les tâches ont étaient supprimés avec succès', 'success')))
                if(dataPaginate && dataPaginate.data.length === selectedRowKeys.length){
                    setPage(page - 1)
                } else {
                    const res = await taskService.list(page, '', user.id);
                    setDatapaginate(res);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalDeletion(false);
            resetRowSelection();
        }
    }

    return (
    <>
        <Header title="Accueil" />
        <Layout className="container">
            <Space direction="vertical" size={24}>
                <Row gutter={[24, 24]}>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <Flex vertical gap={24}>
                                <Flex gap={18} align="center">
                                    <Icon path="./icons/activity.svg" size={48}></Icon>
                                    <Space direction="vertical" size={4}>
                                        <Tag color="default">
                                            <Flex align="center" gap={4}>
                                                <Icon path="./icons/truck.svg" size={12}></Icon>
                                                <Text target="Regular">Route</Text>
                                            </Flex>
                                        </Tag>
                                        <div>
                                            <Text target="Bold">
                                            Nouvelles demandes dans mon agence
                                            </Text>
                                        </div>
                                    </Space>
                                </Flex>
                                <Flex justify="space-between" align="end">
                                        <Text target="CardNumber">{dashboardData ? dashboardData.todo_agency : '-'}</Text>
                                    <a href="#">
                                        <Icon path="./icons/outer-link.svg"></Icon>
                                    </a>
                                </Flex>
                            </Flex>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <Flex vertical gap={24}>
                                <Flex gap={18} align="center">
                                    <Icon path="./icons/target.svg" size={48}></Icon>
                                    <Space direction="vertical" size={4}>
                                        <Tag color="default">
                                            <Flex align="center" gap={4}>
                                                <Icon path="./icons/truck.svg" size={12}></Icon>
                                                <Text target="Regular">Route</Text>
                                            </Flex>
                                        </Tag>
                                        <div>
                                            <Text target="Bold">
                                            Total de mes cotations à traiter
                                            </Text>
                                        </div>
                                    </Space>
                                </Flex>
                                <Flex justify="space-between" align="end">
                                    <Text target="CardNumber">{dashboardData ? dashboardData.todo_assignment : '-'}</Text>
                                    <a href="#">
                                        <Icon path="./icons/outer-link.svg"></Icon>
                                    </a>
                                </Flex>
                            </Flex>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <Flex vertical gap={24}>
                                <Flex gap={18} align="center">
                                    <Icon path="./icons/alert-circle.svg" size={48}></Icon>
                                    <Space direction="vertical" size={4}>
                                        <Tag color="default">
                                            <Flex align="center" gap={4}>
                                                <Icon path="./icons/truck.svg" size={12}></Icon>
                                                <Text target="Regular">Route</Text>
                                            </Flex>
                                        </Tag>
                                        <div>
                                            <Text target="Bold">Total des cotations à relancer</Text>
                                        </div>
                                    </Space>
                                </Flex>
                                <Flex justify="space-between" align="end">
                                    <Text target="CardNumber">{dashboardData ? dashboardData.waiting_acceptation : '-'}</Text>
                                    <a href="#">
                                        <Icon path="./icons/outer-link.svg"></Icon>
                                    </a>
                                </Flex>
                            </Flex>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <Flex vertical gap={24}>
                                <Flex gap={18} align="center">
                                    <Icon path="./icons/activity.svg" size={48}></Icon>
                                    <Space direction="vertical" size={4}>
                                    <Tag color="default">
                                        <Flex align="center" gap={4}>
                                            <Icon path="./icons/waves.svg" size={12}></Icon>
                                            <Text target="Regular">Maritime</Text>
                                        </Flex>
                                    </Tag>
                                    <div>
                                        <Text target="Bold">
                                        Nouvelles demandes dans mon agence
                                        </Text>
                                    </div>
                                    </Space>
                                </Flex>
                                <Flex justify="space-between" align="end">
                                    <Text target="CardNumber">-</Text>
                                    <a href="#">
                                        <Icon path="./icons/outer-link.svg"></Icon>
                                    </a>
                                </Flex>
                            </Flex>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <Flex vertical gap={24}>
                                <Flex gap={18} align="center">
                                    <Icon path="./icons/target.svg" size={48}></Icon>
                                    <Space direction="vertical" size={4}>
                                    <Tag color="default">
                                        <Flex align="center" gap={4}>
                                            <Icon path="./icons/waves.svg" size={12}></Icon>
                                            <Text target="Regular">Maritime</Text>
                                        </Flex>
                                    </Tag>
                                    <div>
                                        <Text target="Bold">
                                        Total de mes cotations à traiter
                                        </Text>
                                    </div>
                                    </Space>
                                </Flex>
                                <Flex justify="space-between" align="end">
                                    <Text target="CardNumber">-</Text>
                                    <a href="#">
                                        <Icon path="./icons/outer-link.svg"></Icon>
                                    </a>
                                </Flex>
                            </Flex>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Card>
                            <Flex vertical gap={24}>
                                <Flex gap={18} align="center">
                                    <Icon path="./icons/alert-circle.svg" size={48}></Icon>
                                    <Space direction="vertical" size={4}>
                                    <Tag color="default">
                                        <Flex align="center" gap={4}>
                                            <Icon path="./icons/waves.svg" size={12}></Icon>
                                            <Text target="Regular">Maritime</Text>
                                        </Flex>
                                    </Tag>
                                    <div>
                                        <Text target="Bold">Total des cotations à relancer</Text>
                                    </div>
                                    </Space>
                                </Flex>
                                <Flex justify="space-between" align="end">
                                    <Text target="CardNumber">-</Text>
                                    <a href="#">
                                        <Icon path="./icons/outer-link.svg"></Icon>
                                    </a>
                                </Flex>
                            </Flex>
                        </Card>
                    </Col>
                </Row>
                  
                {!dataPaginate && !loading && <Empty />}
                {dataPaginate && <>
                    <Table
                            rowKey={"id"}
                            loading={loading}
                            columns={renderColumnsAndSelection(visibleColumns, selectedRowKeys, onEditTask)}
                            dataSource={dataTable}
                            scroll={{ x: 400, y: 1080 }}
                            showSorterTooltip={false}
                            pagination={{
                                current: page,
                                pageSize: dataPaginate?.per_page || 10,
                                total: dataPaginate?.total || 0,
                                onChange: (page) => {
                                    setPage(page)
                                },
                            }}
                            rowSelection={rowSelection}
                            onRow={(record) => {
                            return {
                                    onClick: () => {
                                        const task = dataPaginate?.data?.find(item => item.id === record.id);
                                        if (task) {
                                            setEditingTask(task);
                                            setShowModal(true);
                                        }
                                    }
                                }
                            }}
                        />
                </>}
                
        </Space>
              
        {showModal && <PanelFormTask
            task={editingTask}
            showModal={showModal}
            onClose={(refresh) => onClose(refresh)}
        />}
        {showModalDeletion && <DeletionModal
            modalOpen={showModalDeletion}
            title="Suppression de tâches"
            description="Voulez-vous vraiment supprimer ces tâches ?"
            onCancel={() => setShowModalDeletion(false)}
            onConfirm={() => onDelete()}
        />}
      </Layout>
      </>
  );
}
