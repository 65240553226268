import { Button, Flex, Input, Layout, Space, Table } from "antd";
import Header from "../../components/layout/Header";
import { Icon } from "../../components/ui/typo/Icon";
import { Text } from "../../components/ui/typo/Text";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../models/utils/data-paginate";
import taskService from "../../services/task.service";
import Task, { ETaskStatus } from "../../models/task";
import Avatar from "../../components/ui/avatar/avatar";
import dateHelper from "../../utils/date.helper";
import useDebounce from "../../utils/hooks/useDebounce";
import ButtonColumn from "../../components/ui/buttons/button-column";
import { columns } from "../../configs/TaskTableColumns";
import useTable from "../../utils/hooks/useTable";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import PanelFormTask from "../../components/core/tasks/panel-form-task";
import StatusTask from "../../components/core/tasks/status-task";
import LabelTaskName from "../../components/core/notes/label-task-name";
import DeletionModal from "../../components/ui/modal/deletion-modal";
import { addToast } from "../../store/toasts/reducer";
import Toast from "../../models/utils/toast";
import { setRemainingTasks } from "../../store/tasks/reducer";

export default function ListTasksPage() {
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>('');
    const { selectedRowKeys, rowSelection, visibleColumns, setVisibleColumns, renderColumnsAndSelection, showModalDeletion, setShowModalDeletion, resetRowSelection } = useTable(columns)
    const [editingTask, setEditingTask] = useState<Task>();
    const [filterOnMe, setFilterOnMe] = useState(false);
    const { value: user} = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const res = await taskService.list(page, search, filterOnMe ? user?.id : undefined);
                setDatapaginate(res);
                await refreshMenuTasksRemaining();
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [page, search, filterOnMe])

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Task) => {
            return {
                id: item.id,
                key: item.id,
                title: <LabelTaskName task={item} /> || '-',
                user: item.user ? <Avatar placeholder={item.user.getLettersName()} /> : '-',
                due_date: item.due_date ? dateHelper.dateToDDMMYYYY(item.due_date) : '-',
                client_name: item.client?.name || '-',
                status: <StatusTask task={item} /> || '-',
                reference: item.reference || '',
            }
        });
    }, [dataPaginate]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);

    async function onClose(refresh?: boolean) {
        setShowModal(false)
        setEditingTask(undefined);
        if (refresh) {
            try {
                setLoading(true);
                const res = await taskService.list(page, search, filterOnMe ? user?.id : undefined);
                res.setData(res.data.map(item => new Task(item)));
                setDatapaginate(res);
                await refreshMenuTasksRemaining();
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                resetRowSelection();
            }
        }
    }

    async function onEditTask() {
        const selectedTask = dataPaginate?.data?.find(item => item.id === selectedRowKeys[0]);

        if (selectedTask) {
            const task = await taskService.find(selectedTask.id);
            setEditingTask(task);
            setShowModal(true);
        }
    }

     async function onDelete() {
        try {
            setLoading(true);
            const listToDelete = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToDelete) {
                for (const item of listToDelete) {
                    await taskService.destroy(item.id);

                }
                dispatch(addToast(new Toast('Les tâches ont étaient supprimés avec succès', 'success')))
                if(dataPaginate && dataPaginate.data.length === selectedRowKeys.length){
                    setPage(page - 1)
                } else {
                    const res = await taskService.list(page, search, filterOnMe ? user?.id : undefined);
                    setDatapaginate(res);
                }
                await refreshMenuTasksRemaining();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalDeletion(false);
            resetRowSelection();
        }
     }
    
    async function refreshMenuTasksRemaining() {
        if (user) {
            const tasksPaginated = await taskService.list(1, '', user.id, undefined, undefined, ETaskStatus.TODO);
            dispatch(setRemainingTasks(tasksPaginated.total));
        }
    }

    return (
        <>
            <Header title="Tâches">
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <Space size={6}>
                        <Icon path="/icons/plus.svg" size={20} />
                        <Text target="Bold">Ajouter une tâche</Text>
                    </Space>
                </Button>
            </Header>
            <Layout className="container">
                 <Flex vertical gap={16}>
                    <Flex justify="space-between">
                        <Input
                        size="large"
                        placeholder="Rechercher..."
                        prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                        style={{ width: 320 }}
                        onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Space size={8}>
                            <Button className={filterOnMe ? "selected-button" : ''} onClick={() => setFilterOnMe(!filterOnMe)}>
                                <Space size={6}>
                                    <Icon path="/icons/task.svg" size={20} />
                                    <Text target="Bold">Mes tâches</Text>
                                </Space>
                            </Button>
                            <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} keyCache="tasks" />
                        </Space>
                    </Flex>

                    <Table
                        rowKey={"id"}
                        loading={loading}
                        columns={renderColumnsAndSelection(visibleColumns, selectedRowKeys, onEditTask)}
                        dataSource={dataTable}
                        scroll={{ x: 1327, y: 1080 }}
                        showSorterTooltip={false}
                        pagination={{
                            current: page,
                            pageSize: dataPaginate?.per_page || 10,
                            total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page)
                            },
                        }}
                        rowSelection={rowSelection}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    const task = dataPaginate?.data?.find(item => item.id === record.id);
                                    if (task) {
                                        setEditingTask(task);
                                        setShowModal(true);
                                    }
                                }
                            }
                        }}
                    />
                </Flex>
            </Layout>
            {showModal && <PanelFormTask task={editingTask} showModal={showModal} onClose={(refresh) => onClose(refresh)} />}
            {showModalDeletion && <DeletionModal
                modalOpen={showModalDeletion}
                title="Suppression de tâches"
                description="Voulez-vous vraiment supprimer ces tâches ?"
                onCancel={() => setShowModalDeletion(false)}
                onConfirm={() => onDelete()}
            />}
        </>
    );
}