import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import Customer from "../models/customer";
import User from "../models/user";

async function list(page: number = 1, search: string = '', pageSize = 10) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('page_size', pageSize.toString());
    if (search) {
        queryParams.append('search', search);
    }

    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/clients`, params).then(async (response: AxiosResponse) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map(item => new Customer(item)));

        return dataPaginate;
    })
}

async function find(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/clients/${id}`).then(async (response: AxiosResponse) => {
        return new Customer(response.data);
    })
}

async function getDropdownReferences(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/clients/${id}/dropdown/references`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function getGrantedUsers(customerId: number, search?: string) {

    const queryParams = new URLSearchParams();
    if (search) {
        queryParams.append('search', search);
    }

    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/clients/${customerId}/granted-users`, params).then(async (response: AxiosResponse) => {
        return response.data.map((item: any) => new User(item));
    })
}

export default {
    list,
    find,
    getDropdownReferences,
    getGrantedUsers
}