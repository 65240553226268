import Panel from "../../ui/panel/panel";
import { Button, Col, Form, Row, Space } from "antd";
import { Text } from "../../ui/typo/Text";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../../models/utils/data-paginate";
import useDebounce from "../../../utils/hooks/useDebounce";
import { Icon } from "../../ui/typo/Icon";
import { useForm } from "react-hook-form";
import ErrorsForm from "../../ui/errors/errors-form/errors-form";
import ErrorApi from "../../../models/utils/error-api";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import FormInput from "../../form/form-input";
import FormSelectSingle from "../../form/form-select-single";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import Task, { ETaskPriority, ETaskStatus, getPriorityLabel } from "../../../models/task";
import customerService from "../../../services/customer.service";
import Customer from "../../../models/customer";
import taskService from "../../../services/task.service";
import FormTextarea from "../../form/form-textarea";
import FormDatePicker from "../../form/form-datepicker";
import User from "../../../models/user";
import userService from "../../../services/user.service";
import dateHelper from "../../../utils/date.helper";
import UserTag from "../commons/user-tag/user-tag";
import StatusTask from "./status-task";
import ReadonlyField from "../../form/readonly-field";
import { Link } from "react-router-dom";

interface IProps{
    showModal: boolean;
    onClose: (refresh?: boolean) => void;
    task?: Task;
    customerId?: number;
    reference?: string
}
type Inputs = {
    title: string,
    due_date: Date,
    description: string,
    client_id: number,
    reference?: string,
    user_id: number,
    priority: number
}
export default function PanelFormTask({ showModal, onClose, task, customerId, reference }: IProps) {
    const schema: any = yup.object().shape({
        title: yup.string().required("Le titre est requis !"),
        due_date: yup.date().required("La date de fin est requise !"),
        description: yup.string().optional(),
        client_id: yup.number().required("Le client est requis !").default(customerId),
        reference: yup.string().optional().default(reference),
        user_id: yup.number().required("L'utilisateur est requis !"),
        priority: yup.number().required("La priorité est requise !"),
    })

    const [initialsClientsPaginaged, setInitialsClientsPaginated] = useState<DataPaginate>();
    const [clientsPaginated, setClientsPaginated] = useState<DataPaginate>();
    const [clientSearch, setClientSearch] = useState<string>('');
    const [usersPaginated, setUsersPaginated] = useState<DataPaginate>();
    const [userSearch, setUserSearch] = useState<string>('');
    const [referencesOptions, setReferenceOptions] = useState([]);

    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid, errors },
        reset,
        watch,
        setValue
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange'
    })

    const customerIdForm = watch('client_id');
    const referenceForm = watch('reference');

    const [loading, setLoading] = useState(false);
    const [errorApi, setErrorApi] = useState<ErrorApi>();
    const dispatch = useAppDispatch();

    const [agenciesPage, setAgenciesPage] = useState(1);
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await customerService.list(agenciesPage, clientSearch);
                if (agenciesPage > 1 && clientsPaginated) {
                    res.setData([...clientsPaginated.data, ...res.data]);
                }
                setClientsPaginated(res);
                if(!initialsClientsPaginaged){
                    setInitialsClientsPaginated(res);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [clientSearch, agenciesPage])

    useEffect(() => {
        async function fetchReferences() {
            if (customerId) {
                const res = await customerService.getDropdownReferences(customerId);
                setReferenceOptions(res.map((item: any) => ({ label: item.code, value: item.code })));
            }
        }
        fetchReferences();
    }, [customerId])    

    useEffect(() => {
        async function fetchReferences() {
            try {
                if (customerIdForm) {
                    const res = await customerService.getDropdownReferences(customerIdForm);
                    setReferenceOptions(res.map((item: any) => ({ label: item.code, value: item.code })));
                    if (res.find((item: any) => item.code === referenceForm) === undefined) {
                        setValue('reference', '');
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

        fetchReferences();
    }, [customerIdForm])


    useEffect(() => {
        async function fetchData() {
            try {
                const res = await userService.list(1, userSearch);
                setUsersPaginated(res);
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [userSearch])

    const title = useMemo(() => task ? task.title : "Nouvelle tâche", [task]);

    const clientOptions = useMemo(() => {
        if (!clientsPaginated || !clientsPaginated.data) return [];
        
        return clientsPaginated.data.map((customer: Customer) => ({ label: customer.name, value: customer.id }));
    }, [clientsPaginated]);

    const handleSearchClient = useDebounce((term) => {
        setAgenciesPage(1);
        setClientSearch(term);
    } , 500);

    const userOptions = useMemo(() => {
        if (!usersPaginated || !usersPaginated.data) return [];
        
        return usersPaginated.data.map((user: User) => ({ label: user.getFullName(), value: user.id }));
    }, [usersPaginated]);

    const handleSearchUser = useDebounce((term) => setUserSearch(term), 500);

    async function onSubmit(data: any) {
        try {
            setLoading(true);
            setErrorApi(undefined);
            await taskService.create(data);
            dispatch(addToast(new Toast("La tâche a été créée avec succès !", 'success')))
            reset();
            onClose(true);
        } catch (e: any) {
            console.log(e)
            if (e.response) {
                setErrorApi(new ErrorApi(e.response));
            }
        } finally {
            setLoading(false);
        }
    }

    function closeModal() {
        reset();
        onClose();
    }

    function showRunningButton() {
        return task && task.status === ETaskStatus.TODO;
    }

     function showDoneButton() {
        return task && (task.status === ETaskStatus.TODO || task.status === ETaskStatus.IN_PROGRESS);
    }

    async function upgradeStatus(status: ETaskStatus) {
        if (task) {
            try {
                setLoading(true);
                await taskService.updateStatus(task?.id, status);
                dispatch(addToast(new Toast("La tâche a été mise à jour avec succès !", 'success')))
                onClose(true);
            } catch (e: any) {
                dispatch(addToast(new Toast("La tâche n'a pas été mise à jour !", 'error')))
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <Panel
            title={<Text target="H2">{title}</Text>}
            isOpen={showModal}
            onClose={() => closeModal()}
        >
  
            {!task && <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>
                <div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <FormInput
                                label="Libellé du rappel"
                                required
                                name="title"
                                placeholder="Entrer le libellé du rappel"
                                control={control}
                                error={errors?.title}
                            />
                        </Col>

                        <Col span={12}>
                            <FormDatePicker
                                label="Date d'échance"
                                required
                                name="due_date"
                                control={control}
                                error={errors?.due_date}
                            />
                            
                        </Col>

                        <Col span={24}>
                            <FormTextarea
                                label="Description"
                                name="description"
                                placeholder="Entrer la description"
                                control={control}
                                error={errors?.description}
                            />
                        </Col>

                        <Col span={12}>
                            <FormSelectSingle
                                label="Client"
                                placeholder="Sélectionner un client"
                                name="client_id"
                                defaultValue={customerId ? customerId : undefined}
                                control={control}
                                options={clientOptions}
                                onSearch={handleSearchClient}
                                required
                                hasCustomFilter={true}
                                disabled={customerId ? true : false}
                                loadMore={() => {
                                    if (clientsPaginated?.last_page && agenciesPage < clientsPaginated?.last_page) {
                                        setAgenciesPage(agenciesPage + 1)
                                    }
                                }
                                }
                            />
                        </Col>

                        <Col span={12}>
                            <FormSelectSingle
                                label="Sélection de la cotation ou du contrat"
                                placeholder=""
                                name="reference"
                                defaultValue={reference ? reference : undefined}
                                control={control}
                                options={referencesOptions}
                                onSearch={() => null}
                                disabled={reference ? true : false}
                            />
                        </Col>

                        <Col span={12}>
                            <FormSelectSingle
                                label="Attribué à"
                                placeholder="Sélectionner un utilisateur"
                                name="user_id"
                                control={control}
                                options={userOptions}
                                onSearch={handleSearchUser}
                                required
                            />
                        </Col>

                        <Col span={12}>
                            <FormSelectSingle
                                label="Priorité"
                                placeholder="Sélectionner une priorité"
                                name="priority"
                                control={control}
                                options={Object.values(ETaskPriority).filter((item: any) => !isNaN(item)).map((priority: any) => ({ label: getPriorityLabel(priority), value: priority }))}
                                onSearch={() => null}
                                required
                            />
                        </Col>
                        
                    </Row>

                    <ErrorsForm errorApi={errorApi} />

                </div>

                <div className="ant-drawer-footer">
                    <Space
                        size={16}
                        style={{ justifyContent: "flex-end", width: "100%" }}
                    >
                        <Button onClick={() => closeModal()} icon={<Icon path="/icons/arrow-left.svg" size={20} />}>
                            <Text target="Bold">Annuler</Text>
                        </Button>
                        
                        <Button htmlType="submit" disabled={!isDirty || !isValid || loading} type="primary" icon={<Icon path="/icons/check.svg" size={20} />}>
                            <Space size={6}>
                                <Text target="Bold">Valider</Text>
                            </Space>
                        </Button>
                    </Space>
                </div>
            </Form>}

            {task && <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <ReadonlyField
                            label="Date d'échéance"
                            readonly
                            contentReadonly={task && task.due_date ? <Text target="Regular">{dateHelper.dateToDDMMYYYY(task.due_date)}</Text> : <></>}
                        />
                    </Col>
                    
                    <Col span={12}>
                        <ReadonlyField
                            label="Attribué à"
                            readonly
                            contentReadonly={task && task.user ? <UserTag user={task.user} /> : <></>}
                        />
                    </Col>

                    <Col span={24}>
                        <ReadonlyField
                            label="Description"
                            value={task.description}
                            readonly
                        />
                    </Col>

                    <Col span={12}>
                        <ReadonlyField
                            label="Client"
                            value={task.client.name}
                            readonly
                        />
                    </Col>

                    <Col span={12}>
                        {task.demande ? <Link to={`/road-quotations/${task.demande.id}`}>
                            <ReadonlyField
                                label="Cotation du contrat de transport"
                                value={task.reference}
                                readonly
                            />
                        </Link> : <ReadonlyField
                            label="Cotation du contrat de transport"
                            value={task.reference}
                                readonly
                        />}
                    </Col>

                    <Col span={12}>
                        <ReadonlyField
                            label="Priorité"
                            value={getPriorityLabel(task.priority)}
                            readonly
                        />
                    </Col>

                    <Col span={12}>
                        <ReadonlyField
                            label="Status"
                            readonly
                            contentReadonly={task ? <StatusTask task={task} /> : <></>}
                        />
                    </Col>

                </Row>
                <div className="ant-drawer-footer">
                    <Space
                        size={16}
                        style={{ justifyContent: "flex-end", width: "100%" }}
                    >
                        <Button onClick={() => closeModal()} icon={<Icon path="/icons/arrow-left.svg" size={20} />}>
                            <Text target="Bold">Annuler</Text>
                        </Button>

                        {showRunningButton() && <Button onClick={() => upgradeStatus(ETaskStatus.IN_PROGRESS)} type="primary" disabled={loading} icon={<Icon path="/icons/edit-2.svg" size={20} />} className="btn-danger">
                            <Space size={6}>
                                <Text target="Bold">En cours</Text>
                            </Space>
                        </Button>}
                        
                        {showDoneButton() && <Button onClick={() => upgradeStatus(ETaskStatus.DONE)} type="primary" disabled={loading} icon={<Icon path="/icons/check.svg" size={20} />} className="btn-green">
                            <Space size={6}>
                                <Text target="Bold">Fait</Text>
                            </Space>
                        </Button>}
                    </Space>
                </div>
            </div>}
        </Panel>
        
    )
}
